import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const FirestorePage: FC = () => (
  <Container>
    <Row>
      <Col>
        <h1>Firestore</h1>
      </Col>
    </Row>
  </Container>
);
