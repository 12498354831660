import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PinkPage } from '../pages/dev/PinkPage';
import { TealPage } from '../pages/dev/TealPage';
import { FirestorePage } from '../pages/dev/FirestorePage';
import { DashboardPage } from '../pages/dashboard/DashboardPage';

export const RootRoute: FC = () => {
  return (
    <Switch>
      <Route path="/pink">
        <PinkPage />
      </Route>
      <Route path="/teal">
        <TealPage />
      </Route>
      <Route path="/firestore">
        <FirestorePage />
      </Route>
      {/*<Route path="/faker">*/}
      {/*  <FakerPage/>*/}
      {/*</Route>*/}
      <Route path="/">
        <DashboardPage />
      </Route>
    </Switch>
  );
};
