import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RootRoute } from './routes/RootRoute';
import { NavBar } from './components/navigation/NavBar';
import { useAuthState } from './providers/authentication/AuthProvider';
import { LoginPage } from './pages/login/LoginPage';
import { UIProvider } from './providers/ui/UIProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App: FC = () => {
  const { isLoggedIn } = useAuthState();

  return (
    <BrowserRouter>
      <UIProvider>
        {!isLoggedIn && <LoginPage />}
        {isLoggedIn && (
          <>
            <NavBar />
            <RootRoute />
          </>
        )}
      </UIProvider>
      <ToastContainer />
    </BrowserRouter>
  );
};
