import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const PinkPage: FC = () => {
  return (
    <Container style={styles.container}>
      <Row>
        <Col>
          <p>Pink Screen</p>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  container: {
    backgroundColor: 'salmon',
    width: '100%',
    minHeight: '500px',
    marginTop: '50px',
  },
};
