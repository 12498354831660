import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDwkVaP73GONya1PxlwHBiVSolT1Dig76o',
  authDomain: 'wineryxplorer-f6ca4.firebaseapp.com',
  databaseURL: 'https://wineryxplorer-f6ca4.firebaseio.com',
  projectId: 'wineryxplorer-f6ca4',
  storageBucket: 'wineryxplorer-f6ca4.appspot.com',
  messagingSenderId: '597477400254',
  appId: '1:597477400254:web:6b30164f3d81405b0908ef',
  measurementId: 'G-PZNT23N7TX',
};

firebase.initializeApp(firebaseConfig);
console.log('Firebase is initialized');

export const auth = firebase.auth();
export const firestore = firebase.firestore();
