import React, { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { auth } from '../../services/firebase/core/firebaseCore';
import { MOCK_USER, MockUser } from './mocks/user';
import firebase from 'firebase';
import { Button, Modal } from 'react-bootstrap';

const SHOULD_MOCK = process.env.REACT_APP_STAGE === 'mock';

type FirebaseUser = firebase.User;

type User = FirebaseUser | MockUser | undefined;

type SessionType = {
  jwt?: string;
  user?: FirebaseUser;
};

export const Session: SessionType = {
  jwt: undefined,
  user: undefined,
};

interface AuthState {
  isLoggedIn: boolean;
  userProfile?: User;
  roles: string[];
}

const INITIAL_STATE = {
  isLoggedIn: false,
  userProfile: undefined,
  roles: [],
};

const AuthContext = React.createContext<AuthState>(INITIAL_STATE);

interface OwnProps {
  showDebugFooter?: boolean;
}

type Props = OwnProps;

export const AuthProvider: FC<PropsWithChildren<Props>> = ({ children, showDebugFooter }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(SHOULD_MOCK);
  const [userProfile, setUserProfile] = useState<User>(SHOULD_MOCK ? MOCK_USER : undefined);
  const [roles, setRoles] = useState<string[]>([]);

  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    const processUser = async (user: FirebaseUser) => {
      if (!user) {
        setRoles([]);
        setIsLoggedIn(false);
        console.log('Cold state');
      } else {
        const { claims } = await user.getIdTokenResult();
        const userRoles = ['user'];
        if (claims.admin) {
          userRoles.push('admin');
        }
        if (claims.venueManager) {
          userRoles.push('venueManager');
        }
        setIsLoggedIn(true);
        setUserProfile(user);
        Session.user = user;
        Session.jwt = await user.getIdToken();
        console.log('Hot state');
      }
    };

    if (!SHOULD_MOCK) {
      auth.onAuthStateChanged((user) => {
        processUser(user as FirebaseUser);
      });
    }
  }, []);

  const handleLogout = async () => {
    await auth.signOut();
    setUnauthorized(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userProfile, roles }}>
      {children}
      {showDebugFooter ? <AuthFooter isLoggedIn={isLoggedIn} userProfile={userProfile} roles={roles} /> : null}
      <Modal show={unauthorized} onHide={handleLogout}>
        <Modal.Header closeButton>
          <Modal.Title>UNAUTHORIZED</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are not authorized to use this system! Good day.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLogout}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthContext.Provider>
  );
};

const AuthFooter: FC<AuthState> = ({ roles, isLoggedIn, userProfile }) => {
  return (
    <div style={styles.footer}>
      <p>
        {isLoggedIn ? 'Logged in' : 'Not logged in'}&nbsp;|&nbsp;{userProfile?.email || 'No email'}&nbsp;|&nbsp;
        {userProfile?.displayName || 'No Display Name'}
      </p>
      <p>Roles: {roles.join(', ')}</p>
    </div>
  );
};

const styles = {
  footer: {
    position: 'absolute' as const,
    bottom: '0',
    width: '100%',
    backgroundColor: 'salmon',
    height: '100px',
    padding: 10,
    display: 'hidden',
  },
};

export const useAuthState = () => {
  return useContext(AuthContext);
};
